.main {
    display: flex;
    padding: 150px;
    /* background-color: #2b2c2c; */
    padding-left: 400px;
  }
  
  .left-img {
    height: 550px;
    width: 400px;
  }
  
  .right {
    display: flex;
    flex-direction: column;
    padding-left: 100px;
    justify-content: start;
  }
  
  .right-lower {
    padding-top: 50px;
    color: #9a9f75;
    padding-left: 50px;
    font-stretch: expanded;
  }
  
  .right-img {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .right-img img {
    max-width: 100%;
    max-height: 100%;
  }
  
  /* Responsive Styles */
  
  @media (max-width: 768px) {
    /* Adjustments for smaller screens */
    .main {
      flex-direction: column;
      padding: 50px;
    }
  
    .left-img {
      width: 100%;
    }
  
    .right {
      padding-top: 30px;
      padding-left: 0;
    }
  
    .right-lower {
      padding-left: 0;
    }
  }
  
  @media (max-width: 520px) {
    /* Adjustments for even smaller screens */
    .main {
      padding: 30px;
    }
  
    .left-img {
      height: auto;
      width: 100%;
    }
    .banner{
      background-image: url('../images/7str.jpg');
    }
  }
@media screen and (min-width: 600px ) {
  .banner{
    background-image: url('../images/banner.jpg');
  }
}
@media screen and (min-width: 768px ) {
  .banner{
    background-image: url('../images/banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
}
  

  
  