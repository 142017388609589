.fullbackground {
  background-image: url("../images/texture_.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  
  min-height: 100vh;
}
.fullbackground1 {
  background-image: url("../images/texture_.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}
@media screen and (min-width: 600px) {
  .hanuman {
    width: 80%;
    padding-left: 130px;
  }
  .logopic {
    width: 4%;
  }
  .entry{
    font-size: 50px;
  }
  .welcome{
    font-size: 20px;
  }
}
@media screen and (max-width: 600px) {
  .hanuman {
    width: 100%;
    margin-bottom: 50px;
  }
  .logopic {
    width: 17%;
  }
  .entry{
    font-size: 20px;
    padding-bottom: 150px;
  }
  .welcome{
    font-size: 10px;
  }
}

.h:hover {
  color: red !important;
}
.container-fluid{
  background-image: url("../images/texture_.jpg");
  /* background: rgba(56, 2, 2, 0.6); */
box-shadow: -7px 4px 20px 13px rgb(254 0 60 / 37%);
backdrop-filter: blur( 10px );
/* -webkit-backdrop-filter: blur( 0px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 ); */
height: 60px;
}

